<template>
  <b-sidebar
    id="add-new-tag-sidebar"
    :visible="isAddNewTagSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-tag-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ (!tagData.tagging_id) ? 'Add New' : 'Edit' }} Content Tag
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          class="m-1"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger'"
            class="alert-body"
          >
            <ul class="pl-2">
              <li
                v-for="(item, index) in alertDetail.text"
                :key="index"
              >
                <template v-for="txt in item">
                  {{ txt }}
                </template>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(registerUser)"
          @reset.prevent="resetForm"
        >

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Tag Name"
            rules="required"
          >
            <b-form-group
              label="Tag Name"
              label-for="tag-name"
            >
              <b-form-input
                id="tag-name"
                v-model="tagData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-50"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTagSidebarActive',
    event: 'update:is-add-new-tag-sidebar-active',
  },
  props: {
    isAddNewTagSidebarActive: {
      type: Boolean,
      required: true,
    },
    tagData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  setup() {
    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      toast,

      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    resetForm() {
      this.$emit('reset-data')
    },
    registerUser() {
      let targetUrl = '/tag'
      if (this.tagData.tagging_id) {
        targetUrl = '/tag/edit'
      }
      this.$http.post(targetUrl, this.tagData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .catch(error => {
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .then(resp => {
          if (resp.status === 200 && resp.data.success === true) {
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })

            this.$emit('refetch-data')
            this.$emit('update:is-add-new-tag-sidebar-active', false)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-tag-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
