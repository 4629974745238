<template>

  <div>
    <tag-list-add-new
      :is-add-new-tag-sidebar-active.sync="isAddNewTagSidebarActive"
      :tag-data="tagData"
      @reset-data="resetTagData"
      @refetch-data="getTagData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="getTagData()"
              />
              <b-button
                variant="primary"
                @click="isAddNewTagSidebarActive = true"
              >
                <span class="text-nowrap">Add New Tag</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-row class="text-center font-weight-bold">
        <b-col class="border-bottom pb-1">
          Name
        </b-col>
        <b-col class="border-bottom pb-1">
          Author
        </b-col>
        <b-col class="border-bottom pb-1">
          Last Update
        </b-col>
        <b-col class="border-bottom pb-1">
          Action
        </b-col>
      </b-row>

      <!-- draggable -->
      <draggable
        v-model="tagList"
        tag="ul"
        class="list-group list-group-flush cursor-move border-bottom mb-2"
        @change="submitChange"
      >
        <b-list-group-item
          v-for="(listItem, index) in tagList"
          :key="index"
          tag="li"
        >
          <b-row>
            <b-col>
              <b-card-text class="mb-0 font-weight-bold">
                {{ listItem.name }}
              </b-card-text>
            </b-col>
            <b-col>
              <b-card-text class="mb-0">
                <div>
                  {{ listItem.author }}
                  <template v-if="listItem.editor && listItem.editor !== listItem.author">
                    <br><span class="small">Last edit by :</span> {{ listItem.editor }}
                  </template>
                </div>
              </b-card-text>
            </b-col>
            <b-col class="text-center">
              <b-card-text class="mb-0">
                {{ listItem.updated_at | formatDate }}
              </b-card-text>
            </b-col>
            <b-col class="text-center">
              <b-card-text class="mb-0"><b-dropdown
                variant="link"
                no-caret
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item
                  @click="editData(listItem)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="confirmDel(listItem)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
              </b-card-text>
            </b-col>
          </b-row>
        </b-list-group-item>
      </draggable>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton,
  BDropdown, BDropdownItem, BListGroupItem,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TagListAddNew from './TagListAddNew.vue'

// Notification

export default {
  components: {
    TagListAddNew,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BListGroupItem,

    draggable,
  },
  setup() {
    const toast = useToast()
    const isAddNewTagSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'name', sortable: true },
      { key: 'author', sortable: true },
      { key: 'updated_at', label: 'Last Update', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('tagging_id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)

    return {
      toast,
      // Sidebar
      isAddNewTagSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      // Extra Filters
      roleFilter,
    }
  },
  data() {
    return {
      tagList: [],
      dataMeta: '',
      tagData: {},
      update: false,
    }
  },
  created() {
    this.getTagData()
  },
  methods: {
    submitChange(value) {
      this.update = true
      const params = {
        tagging_id: value.moved.element.tagging_id,
        old: value.moved.oldIndex,
        new: value.moved.newIndex,
      }
      this.$http.post('/tag/order', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Tag Order Updated',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error update Tag Order',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getTagData()
    },
    getTagData() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        length: this.perPage,
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      let target = '/tags'
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.tagList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Tag list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.tagData = data
      this.isAddNewTagSidebarActive = true
    },
    resetTagData() {
      this.tagData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/tag/delete', { tagging_id: data.tagging_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Tag has been deleted.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getTagData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error deleting Tag',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
